import { Alert } from "antd";

export default function ErrorFallback({
  error,
}: any) {

  return (
    <Alert
      type="error"
      message={error.code}
      description={error.message}
    />
  )
}
