/**
 *     ∩∩
 *   （´･ω･）
 *  ＿| ⊃／(＿＿_
 * ／ └-(＿＿＿／
 * ￣￣￣￣￣￣￣
 * 是，程序员设计了程序
 * 还是，程序造就了程序员？
 * 这是一个问题！
 */
const PagesMapping = {
    DeptList: 'Dept/DeptList',
    PostList: 'Post/PostList',
    UserList: 'User/UserList',
    RoleList: 'Role/RoleList',
    MenuList: 'Menu/MenuList',
    MenuTreeList: 'Menu/MenuTreeList',
    OpenAPIList: 'OpenAPI/OpenAPIList',
    SecurityCredentialList: 'SecurityCredential/SecurityCredentialList',
    UserCredentialList: 'UserCredential/UserCredentialList',
    AppSetting: 'Settings/App',
    SSOSetting: 'Settings/SSO',
    ParameterSetting: 'Settings/Parameter',
    UserProfile: 'Profile/UserProfile',
    Default: 'Other/Default',
};

export { PagesMapping };
