import type { CacheStore, Page, PortalCtx, PortalUICtx } from '@portal/core'
import { AppHelper, CacheStoreForLocalStorage, defaultUICtx, PortalContext, PortalUIContext } from '@portal/core'
import bootApp from '@portal/core/dist/boot'
import PortalSDK, { CurrentProfileInfo } from '@portal/sdk'
import { PagesMapping } from '@portal/ui'
import { ConfigProvider } from 'antd'
import { clone, get, has, merge } from 'lodash-es'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Theme, ThemeProvider } from 'theme-ui'
import ErrorFallback from './components/ErrorFallback'
import './index.css'

const pages: Page[] = [
  {
    id: '1',
    key: '__index__',
    name: '首页',
    path: '/',
    file: 'App',
    is_entry: true,
  },
  {
    id: '2',
    key: '__login__',
    name: 'login',
    path: '/login',
    file: 'Login',
    is_login: true,
  },
]

type AppData = {
  appId: string;
  token: string;
  UIOptions: Partial<PortalUICtx>;
  portalTheme: string;
  theme: Theme;
  basename?: string;
  path: string;
}

const uiOptionsBuilder = (options?: Partial<PortalUICtx>) => {
  return merge({
    actionBtnType: 'text',
    icons: [],
    iconLoader: () => () => null
  } as Partial<PortalUICtx>, options || {})
}

const collectConfig = async (store: CacheStore): Promise<AppData> => {
  let result: AppData = {
    appId: '',
    token: '',
    UIOptions: uiOptionsBuilder(),
    portalTheme: '',
    theme: {},
    path: '/',
    basename: undefined
  }

  const APP_ID_CACHE_KEY = 'portal_app_id'
  const TOKEN_CACHE_KEY = 'portal_token'

  // @ts-ignore
  const url = new URL(window.location)
  const usp = url.searchParams
  const getParams = (key: string, newValue?: string) => newValue ?? usp.get(key) ?? localStorage.getItem(key) ?? ''

  // @ts-ignore
  if (window.microApp) {
    // @ts-ignore
    const d = window.microApp.getData()
    if (!d) {
      throw new Error('未传递token给portal子应用')
    }
    if (d.token) {
      result.token = d.token
    }
    if (d.appId) {
      result.appId = d.appId
    }
    if (d.portalOptions) {
      if (d.portalTheme && ['iot'].includes(d.portalTheme)) {
        const AntdIcons = await import('@ant-design/icons')
        // 生成可用icons列表
        const icons = Object.keys(AntdIcons)
          .filter(d => d.includes('Outlined'))
          .map(d => {
            return {
              label: d,
              name: d,
              keywords: [d],
            }
          })
        result.UIOptions = uiOptionsBuilder({
          ...d.portalOptions,
          icons,
          iconLoader: (name: string) => {
            const Com = get(AntdIcons, name)
            if (!Com) {
              return () => <span></span>
            }
            return Com
          },
        })
      } else {
        result.UIOptions = uiOptionsBuilder(d.portalOptions)
      }
    }
    if (d.portalTheme) {
      result.portalTheme = d.portalTheme
    }
    if (d.theme) {
      result.theme = d.theme
    }
    result.basename = d.base
    result.path = d.path
  } else {
    const appId = getParams(APP_ID_CACHE_KEY)
    if (appId) {
      result.appId = appId
    }
    const token = getParams(TOKEN_CACHE_KEY)
    if (token) {
      result.token = token
    }
  }
  console.log('result:', result)
  return result
}

const sdk = new PortalSDK({
  appId: '-1',
  apiPrefix: '/api',
  ossUrl: '',
  dataProcess: (data) => {
    if (data.error) {
      throw new Error(data.model)
    }
    return data.model
  },
})

const isPortalUI = (file: string) => file.startsWith('@ui/')

const bootstrap = async () => {

  const cacheStore = new CacheStoreForLocalStorage()

  const helper = new AppHelper(sdk, {
    cacheStore
  })

  const config = await collectConfig(cacheStore)

  sdk.changeToApp(config.appId)
  cacheStore.set(helper.tokenCacheKey, config.token)

  const portalCtx: PortalCtx<CacheStoreForLocalStorage> = {
    sdk,
    helper,
    defaultPage: 'portal.user.list',
    basename: config.basename,
  }

  const portalUICtx = merge(clone(defaultUICtx), config.UIOptions)

  const container = document.getElementById('portal-root')

  const themeCls = config.portalTheme ?? ''
  if (themeCls) {
    container?.classList.add(themeCls)
    import(`./theme/${themeCls}.css`)
  }

  bootApp<CacheStoreForLocalStorage>({
    container,
    basename: config.basename,
    pageLoader: (file: string) => {
      if (isPortalUI(file)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const pageFileName = file.slice(4)
        if (has(PagesMapping, pageFileName)) {
          const pageFilePath = get(PagesMapping, pageFileName)
          return import(`@portal/ui/dist/components/${pageFilePath}.js`)
        }
        return import('./pages/Default')
      } else if (['App', 'Login'].includes(file)) {
        // return import('./pages/Unknown')
        return import(`./pages/${file}.tsx`)
      } else {
        return import(`./pages/Default`)
      }
    },
    appHelper: helper,
    render: (routes: React.ReactNode) => {
      return (
        <BrowserRouter basename={config.basename}>
          <ThemeProvider theme={config.theme}>
            <ConfigProvider
              getPopupContainer={() => container as HTMLDivElement}
            >
              <PortalContext.Provider value={portalCtx}>
                <PortalUIContext.Provider value={portalUICtx}>
                  <Routes>
                    {routes}
                    <Route
                      path="*"
                      element={(
                        <div style={{ padding: '1rem' }}>
                          <p>404 页面未找到，这里什么都没有</p>
                        </div>
                      )}
                    />
                  </Routes>
                </PortalUIContext.Provider>
              </PortalContext.Provider>
            </ConfigProvider>
          </ThemeProvider>
        </BrowserRouter>
      )
    },
    errorBoundaryProps: {
      fallbackRender: (e) => <ErrorFallback {...e} />,
    },
    startup: (profile: CurrentProfileInfo, routes: any, next: any) => {
      console.log('应用已启动...')
      portalCtx.profile = profile
      next()
    },
    failure: (error: any, next: any) => {
      console.log('无法正常载入界面：', error)
      // 这里不需要调用next
      // next会清空token，这不是预期的
      // next()
    },
    pages: pages
  })
}

bootstrap()
